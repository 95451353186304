<template>
  <div id="app">
    <lemon-imui
        :width="lemonWidth"
        :height="lemonHeight"
        simple
        :user="user"
        ref="IMUI"
        @pull-messages="handlePullMessages"
        @send="handleSend"
    >
      <template #cover>
        <div class="cover">
          <span><b>李棋</b>同学</span>
        </div>
      </template>
    </lemon-imui>
  </div>
</template>

<script>
const generateRandId = () => Math.random().toString(36).substr(-8);
import GptChatAsk from "../../../request/expand/GptChat";

export default {
  name: "chatGPTMsg",
  data() {
    return {
      ask: new GptChatAsk(),
      lemonWidth: "100%",
      lemonHeight: "100%",
      user: {
        id: this.$route.query.usrId,
        displayName: this.$route.query.usrName,
        avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
      },
      continuousDialogue: false,
      oldMessageSize: 0
    };
  },
  created() {
    this.lemonHeight = (window.innerHeight - 60) + "px";
    window.onresize = () => {
      this.lemonHeight = (window.innerHeight - 60) + "px";
    };
  },
  mounted() {
    const {IMUI} = this.$refs;
    IMUI.clearMessages('chatGpt');
    this.$refs.IMUI.initContacts([
      {
        id: "chatGpt",
        displayName: this.user.displayName + "的聊天记录",
        avatar: "",
        type: "single",
        index: "T",
        unread: 0,
      },
    ]);
    IMUI.initEditorTools([])
    setTimeout(() => {
      IMUI.changeContact("chatGpt");
    }, 1000);
    this.onMessageClick();
  },
  destroyed() {

  },
  methods: {
    onMessageClick(event) {
      // 获取点击的消息内容
      const messageContent = event.target.textContent;
      // console.log(`点击了消息：${messageContent}`);      // 处理跳转等操作
      // ...      // 阻止事件冒泡和默认行为
      event.stopPropagation();
      event.preventDefault();
    },
    /**
     * 注入消息
     * @param data
     */
    renderMessage(data) {
      const {IMUI} = this.$refs;
      IMUI.appendMessage(data);
      IMUI.messageViewToBottom();
    },
    /**
     * 消息发送
     * @param message
     * @param next
     * @param file
     */
    handleSend(message, next, file) {
    },
    /**
     * 拉取最新消息
     * @param contact
     * @param next
     */
    handlePullMessages(contact, next) {
      this.ask.getGptMessageList({"pageSize": this.oldMessageSize, "usrId": this.user.id}).then(val => {
        this.oldMessageSize = +val.length
        // true 表示到末尾
        next(val, val.length < 50 ? true : false);
      })
    },
    /**
     * 下拉到底部
     */
    messageViewToBottom() {
      const {IMUI} = this.$refs;
      IMUI.messageViewToBottom();
    },
    /**
     * 更新消息
     * @param Message 消息体
     */
    updateMessage(Message) {
      const {IMUI} = this.$refs;
      IMUI.updateMessage(Message)
    }
  },
};
</script>

<style>
.cover {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #ddd;
}

.lemon-editor {
  text-align: left;
  height: 150px;
}

.lemon-editor__inner {
  border-top: solid;
}
</style>